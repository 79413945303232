import { createSlice } from '@reduxjs/toolkit';
import { defaultBottomCardSlice } from '@/redux/modules/bottomCard/bottomCard.types';

const bottomCardSlice = createSlice({
    initialState: defaultBottomCardSlice,
    name: 'bottomCard',
    reducers: {
        dismissMustCheckoutCard: (state) => {
            state.showMustCheckoutCard = false;
        },
    },
});

export const { reducer: bottomCardReducer } = bottomCardSlice;

const { actions } = bottomCardSlice;
export const { dismissMustCheckoutCard } = actions;
