import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { BankAccount } from '@liveauctioneers/caterwaul-components/types/BankAccount';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import {
    DELETE_BANK_ACCOUNT_FAIL,
    DELETE_BANK_ACCOUNT_FAIL_ACTION,
    DELETE_BANK_ACCOUNT_REQUEST,
    DELETE_BANK_ACCOUNT_REQUEST_ACTION,
    DELETE_BANK_ACCOUNT_SUCCESS,
    DELETE_BANK_ACCOUNT_SUCCESS_ACTION,
    FETCH_PLAID_LINK_TOKEN_FAIL,
    FETCH_PLAID_LINK_TOKEN_FAIL_ACTION,
    FETCH_PLAID_LINK_TOKEN_REQUEST,
    FETCH_PLAID_LINK_TOKEN_REQUEST_ACTION,
    FETCH_PLAID_LINK_TOKEN_SUCCESS,
    FETCH_PLAID_LINK_TOKEN_SUCCESS_ACTION,
    LOAD_BANK_ACCOUNTS_FAIL,
    LOAD_BANK_ACCOUNTS_FAIL_ACTION,
    LOAD_BANK_ACCOUNTS_REQUEST,
    LOAD_BANK_ACCOUNTS_REQUEST_ACTION,
    LOAD_BANK_ACCOUNTS_SUCCESS,
    LOAD_BANK_ACCOUNTS_SUCCESS_ACTION,
    SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL,
    SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL_ACTION,
    SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST,
    SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST_ACTION,
    SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS,
    SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS_ACTION,
} from './actions';
import {
    deleteBankAccount,
    fetchBankAccounts,
    fetchPlaidLinkToken,
    postCreatePlaidBankAccount,
} from '@/redux/api/payment';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import ms from 'ms';

const REDUX_STORE_TIME = ms('20m');

export type BankAccountSlice = {
    bankAccounts: BankAccount[];
    bankAccountsLoaded: number;
    bankAccountsLoading: boolean;
    errorMessage: string;
    plaidError: boolean;
    plaidFetching: boolean;
    plaidLinkTokens: { [key: string]: string };
    plaidSuccess: boolean;
};

export const defaultBankAccountSlice: BankAccountSlice = {
    bankAccounts: [],
    bankAccountsLoaded: 0,
    bankAccountsLoading: false,
    errorMessage: '',
    plaidError: false,
    plaidFetching: false,
    plaidLinkTokens: {},
    plaidSuccess: false,
};

export const bankAccount: Reducer<BankAccountSlice> = handleActions(
    {
        [combineActions(DELETE_BANK_ACCOUNT_FAIL, LOAD_BANK_ACCOUNTS_FAIL)]: (
            state: BankAccountSlice,
            action: DELETE_BANK_ACCOUNT_FAIL_ACTION | LOAD_BANK_ACCOUNTS_FAIL_ACTION
        ): BankAccountSlice => {
            return {
                ...state,
                bankAccountsLoading: false,
                errorMessage: action.payload,
            };
        },
        [combineActions(DELETE_BANK_ACCOUNT_REQUEST, LOAD_BANK_ACCOUNTS_REQUEST)]: (
            state: BankAccountSlice
        ): BankAccountSlice => {
            return {
                ...state,
                bankAccountsLoaded: 0,
                bankAccountsLoading: true,
                errorMessage: '',
            };
        },
        [combineActions(FETCH_PLAID_LINK_TOKEN_FAIL, SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL)]: (
            state: BankAccountSlice
        ): BankAccountSlice => {
            return {
                ...state,
                plaidError: true,
                plaidFetching: false,
                plaidSuccess: false,
            };
        },
        [combineActions(FETCH_PLAID_LINK_TOKEN_REQUEST, SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST)]: (
            state: BankAccountSlice
        ): BankAccountSlice => {
            return {
                ...state,
                plaidError: false,
                plaidFetching: true,
                plaidSuccess: false,
            };
        },
        [DELETE_BANK_ACCOUNT_SUCCESS]: (state: BankAccountSlice): BankAccountSlice => {
            return {
                ...state,
                bankAccounts: [],
                errorMessage: '',
            };
        },
        [FETCH_PLAID_LINK_TOKEN_SUCCESS]: (
            state: BankAccountSlice,
            action: FETCH_PLAID_LINK_TOKEN_SUCCESS_ACTION
        ): BankAccountSlice => ({
            ...state,
            plaidError: false,
            plaidFetching: false,
            plaidLinkTokens: {
                ...state.plaidLinkTokens,
                [action.meta.accessToken]: action.payload,
            },
            plaidSuccess: true,
        }),
        [LOAD_BANK_ACCOUNTS_SUCCESS]: (
            state: BankAccountSlice,
            action: LOAD_BANK_ACCOUNTS_SUCCESS_ACTION
        ): BankAccountSlice => {
            return {
                ...state,
                bankAccounts: action.payload || [],
                bankAccountsLoaded: Date.now(),
                bankAccountsLoading: false,
                errorMessage: '',
            };
        },
        [SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS]: (
            state: BankAccountSlice,
            action: SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS_ACTION
        ): BankAccountSlice => {
            // Remove the old pending account
            let bankAccounts = state.bankAccounts.reduce((acc, cur) => {
                if (cur.plaidToken !== action.meta.accessToken) {
                    return [...acc, cur];
                }
                return acc;
            }, []);
            // Add the new account
            bankAccounts = [...bankAccounts, action.payload];
            return {
                ...state,
                bankAccounts,
                plaidError: false,
                plaidFetching: false,
                plaidSuccess: true,
            };
        },
    },
    defaultBankAccountSlice
);

const stateSelector = (state: GlobalState) => state;
const bankAccountSlice = createSelector(stateSelector, (state) => state.bankAccount);
const passthroughSelector = (_: GlobalState, id: number | string) => id;

export const getBankAccounts = createSelector(bankAccountSlice, (state) => state.bankAccounts);
export const getBankAccountsLoading = createSelector(bankAccountSlice, (state) => state.bankAccountsLoading);
export const getBankAccountsLoaded = createSelector(bankAccountSlice, (state) => state.bankAccountsLoaded);

export const defaultBankAccountSelector = createSelector(
    [getBankAccounts, passthroughSelector],
    (bankAccounts, providerId) =>
        bankAccounts?.find((bankAccount) => bankAccount.defaultAccount && bankAccount.providerId === providerId)
);

export const getPlaid = createSelector(bankAccountSlice, (state) => state);
export const getPlaidLinkToken = createSelector(
    [getPlaid, passthroughSelector],
    (plaidState, id) => plaidState.plaidLinkTokens[id] ?? ''
);
export const getPlaidFetching = createSelector(bankAccountSlice, (state) => state.plaidFetching);
export const getPlaidError = createSelector(bankAccountSlice, (state) => state.plaidError);

const shouldFetchBankAccounts = createSelector(
    getBankAccountsLoaded,
    getBankAccountsLoading,
    (loaded: number, loading: boolean) => {
        if (loading) {
            return false;
        }
        const time = Date.now();
        const diff = time - loaded;
        return !Boolean(loaded) || diff > REDUX_STORE_TIME;
    }
);

export const fetchBankAccountsIfNeeded = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    if (!shouldFetchBankAccounts(state)) {
        return Promise.resolve({});
    }

    try {
        const authToken = getAuthToken(state);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_BANK_ACCOUNTS_REQUEST,
        } as LOAD_BANK_ACCOUNTS_REQUEST_ACTION);

        const response = await fetchBankAccounts({
            authToken,
            bidderId,
            deployment,
        });
        return dispatch({
            payload: response.payload,
            type: LOAD_BANK_ACCOUNTS_SUCCESS,
        } as LOAD_BANK_ACCOUNTS_SUCCESS_ACTION);
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_BANK_ACCOUNTS_FAIL,
        } as LOAD_BANK_ACCOUNTS_FAIL_ACTION);
    }
};

export const submitDeleteBankAccount =
    (bankAccountId: number, plaidAccountId?: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const bidderId = getBidderId(state);
            const deployment = getDeployment(state);

            dispatch({
                type: DELETE_BANK_ACCOUNT_REQUEST,
            } as DELETE_BANK_ACCOUNT_REQUEST_ACTION);

            await deleteBankAccount({
                authToken,
                bankAccountId,
                bidderId,
                deployment,
                plaidAccountId,
            });

            dispatch({
                type: DELETE_BANK_ACCOUNT_SUCCESS,
            } as DELETE_BANK_ACCOUNT_SUCCESS_ACTION);

            dispatch(fetchBankAccountsIfNeeded());
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: DELETE_BANK_ACCOUNT_FAIL,
            } as DELETE_BANK_ACCOUNT_FAIL_ACTION);
        }
    };

export const fetchPlaidLinkTokenForBidder =
    (bidderId: number, accessToken: string = '') =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: FETCH_PLAID_LINK_TOKEN_REQUEST,
            } as FETCH_PLAID_LINK_TOKEN_REQUEST_ACTION);

            const response = await fetchPlaidLinkToken({
                accessToken,
                authToken,
                bidderId,
                deployment,
            });

            dispatch({
                meta: { accessToken },
                payload: response?.payload,
                type: FETCH_PLAID_LINK_TOKEN_SUCCESS,
            } as FETCH_PLAID_LINK_TOKEN_SUCCESS_ACTION);
        } catch (error) {
            dispatch({
                type: FETCH_PLAID_LINK_TOKEN_FAIL,
            } as FETCH_PLAID_LINK_TOKEN_FAIL_ACTION);
        }
    };

export const submitCreatePlaidBankAccount =
    (bidderId: number, token: string, accountID: string, firstName: string, lastName: string, accessToken?: string) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                type: SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST,
            } as SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST_ACTION);

            const response = await postCreatePlaidBankAccount({
                accessToken,
                accountID,
                authToken,
                bidderId,
                deployment,
                firstName,
                lastName,
                token,
            });

            dispatch({
                meta: { accessToken },
                payload: response.payload as BankAccount,
                type: SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS,
            } as SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS_ACTION);
        } catch (error) {
            dispatch({
                type: SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL,
            } as SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL_ACTION);
        }
    };
