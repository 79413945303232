import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { BiddingInfo } from '@/types/BiddingInfo';

/**
 * @category Item API
 * @see fetchBidderBiddingAll
 */
type FetchBidderBiddingAllResponse = {
    data: {
        biddingInfos: BiddingInfo[];
    };
    message: string;
    success: boolean;
};

export type FetchBidderBiddingAllTransformedResponse = {
    error: boolean;
    meta: string;
    payload: {
        allBidItemIds: number[];
        leadingBidItemIds: number[];
        pastBidItemIds: number[];
        pastBidItems: BiddingInfo[];
        upcomingBidItemIds: number[];
        upcomingBidItems: BiddingInfo[];
        wonBidItemIds: number[];
    };
};

const transformBiddingInfoAll = ({
    data,
    message,
    success,
}: FetchBidderBiddingAllResponse): FetchBidderBiddingAllTransformedResponse => {
    const { biddingInfos } = data;
    const allBidItemIds = biddingInfos.map((i) => i.itemId);
    const leadingBidItemIds = biddingInfos
        .filter((i) => i.bidderHasBid && i.bidderHasHighBid && i.isAvailable)
        .map((i) => i.itemId);
    const pastBidItemIds = biddingInfos.filter((i) => !i.isAvailable).map((i) => i.itemId);
    const pastBidItems = biddingInfos.filter((i) => !i.isAvailable);
    const upcomingBidItemIds = biddingInfos.filter((i) => i.isAvailable).map((i) => i.itemId);
    const upcomingBidItems = biddingInfos.filter((i) => i.isAvailable);
    const wonBidItemIds = biddingInfos
        .filter((i) => i.bidderHasBid && i.bidderHasHighBid && i.isSold)
        .map((i) => i.itemId);

    return {
        error: !Boolean(success),
        meta: message,
        payload: {
            allBidItemIds,
            leadingBidItemIds,
            pastBidItemIds,
            pastBidItems,
            upcomingBidItemIds,
            upcomingBidItems,
            wonBidItemIds,
        },
    };
};

/**
 * @category Item API
 * @see fetchBidderBiddingAll
 */
type FetchBidderBiddingAllParams = {
    authToken: string;
    deployment: string;
};

/**
 * GET request to item-api service to retrieve all bidding information for authed user
 * @function fetchBidderBiddingAll
 * @category Item API
 * @param {FetchBidderBiddingAllParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/bidder/bidding/all
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/bidding/routes/get-bidder-bidding-info-all.ts
 */
export const fetchBidderBiddingAll = ({ authToken, deployment }: FetchBidderBiddingAllParams) =>
    new Promise<FetchBidderBiddingAllTransformedResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'bidder/bidding/all',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformBiddingInfoAll,
            })
        );
    });
