import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { BidderCardStatusType } from '@/redux/modules/bidder/cardStatus/bidderCardStatus.types';

/**
 * @category Payments API
 * @see getBidderCardStatus
 */
type GetBidderCardStatusParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

/**
 * @category Payments API
 * @see getBidderCardStatus
 */
export type GetBidderCardStatusResponse = {
    error: boolean;
    payload: BidderCardStatusType;
};

/**
 * getBidderCardStatus - gets the current Bidder card-on-file validity status
 * @category Payments API
 * @function getBidderCardStatus
 * @param {GetBidderCardStatusParams}
 * @returns {GetBidderCardStatusResponse}
 * @see https://redstripe-DEPLOYMENT.liveauctioneers.com/payment-api/cards/${bidderId}/status
 * @see https://us-east-1.console.aws.amazon.com/ecs/v2/clusters/prod/services/redstripe-prod/tasks?region=us-east-1
 */
export const getBidderCardStatus = ({ authToken, bidderId, deployment }: GetBidderCardStatusParams) =>
    new Promise<GetBidderCardStatusResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `cards/${bidderId}/status`,
            authToken,
            deployment,
            path: ApiHosts.Redstripe,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
