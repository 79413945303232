import { BidIncrementCatalogData } from '@/types/BidIncrement';
import { BidIncrementsSlice, defaultBidIncrementsSlice } from './bidIncrements.types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchBidIncrements } from './bidIncrements.actions';
import {
    LOAD_SELLER_ENDED_CATALOGS_SUCCESS,
    LoadSellerEndedCatalogsSuccessAction,
} from '@/redux/modules/actions/sellerEndedCatalogs';
import {
    LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS,
    LoadSellerUpcomingCatalogsSuccessAction,
} from '@/redux/modules/actions/sellerUpcomingCatalogs';
import { loadCatalogs } from '../catalog/catalogs/catalog.actions';
import { loadHomeData } from '../home/home.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';

const fetchBidIncrementsReducer = (slice: BidIncrementsSlice, catalogBidIncrementsData: BidIncrementCatalogData[]) => {
    catalogBidIncrementsData.forEach(({ bidIncrements, catalogId }) => {
        slice.byId[catalogId] = uniqBy(bidIncrements, ({ fromAmount }) => fromAmount);
        slice.loaded[catalogId] = Date.now();
        slice.loading = difference(slice.loading, [catalogId]);
    });
};

const bidIncrementsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchBidIncrements.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, [meta.arg]);
        });
        builder.addCase(fetchBidIncrements.fulfilled, (slice, { payload }) =>
            fetchBidIncrementsReducer(slice, payload)
        );
        builder.addCase(fetchBidIncrements.rejected, (slice, { meta }) => {
            slice.loading = difference(slice.loading, [meta.arg]);
        });

        builder.addCase(loadCatalogs.fulfilled, (slice, { payload }) =>
            fetchBidIncrementsReducer(slice, payload.data.bidIncrements)
        );

        builder.addCase(loadHomeData.fulfilled, (slice, { payload }) =>
            fetchBidIncrementsReducer(slice, payload.bidIncrements)
        );

        builder.addCase<typeof LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS, LoadSellerUpcomingCatalogsSuccessAction>(
            LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS,
            (slice, { payload }) => fetchBidIncrementsReducer(slice, payload.bidIncrements)
        );
        builder.addCase<typeof LOAD_SELLER_ENDED_CATALOGS_SUCCESS, LoadSellerEndedCatalogsSuccessAction>(
            LOAD_SELLER_ENDED_CATALOGS_SUCCESS,
            (slice, { payload }) => fetchBidIncrementsReducer(slice, payload.bidIncrements)
        );
    },
    initialState: defaultBidIncrementsSlice,
    name: 'bidIncrements',
    reducers: {},
});

export const { reducer: bidIncrementsReducer } = bidIncrementsSlice;
