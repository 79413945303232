import { Deployment } from '@liveauctioneers/hammer-ui-payments/types';

export type BidderCardStatusType = {
    bidderId: number;
    cardId: number;
    isValid: boolean;
    providerId: number;
    updatedDate: string;
};

export type LoadBidderCardStatusParams = {
    authToken: string;
    bidderId: number;
    deployment: Deployment | 'test' | '';
};

export type LoadBidderCardStatusIfNeededParams = {
    bidderId?: number;
    token?: string;
};

export type BidderCardStatusSlice = {
    isLoading: boolean;
    status: BidderCardStatusType;
};

export const defaultBidderCardStatusSlice: BidderCardStatusSlice = {
    isLoading: false,
    status: {
        bidderId: 0,
        cardId: 0,
        isValid: false,
        providerId: 0,
        updatedDate: '',
    },
};
