import { ApiHosts, handleResponseOld, makeGet, makePost } from '@/redux/api/helpers';
import { BidderPreference } from '@/types/BidderPreference';

type BidderPreferencesResponse = {
    error: boolean;
    payload: BidderPreference;
};

/**
 * @category User API
 * @see getBidderPreferences
 */
type GetBidderPreferencesParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};
/**
 * Updates user's reseller type via Post request to User API
 * @function getBidderPreferences
 * @category User API
 * @param {GetBidderPreferencesParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/${bidderId}/preferences
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/cfmxni/methods/GET
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnGetBidderPreference
 */

export const getBidderPreferences = ({ authToken, bidderId, deployment }: GetBidderPreferencesParams) =>
    new Promise<BidderPreferencesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `${bidderId}/preferences`,
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category User API
 * @see postBidderPreferences
 */
type PostBidderPreferencesParams = {
    authToken: string;
    bidderId: number;
    bidderPreference: BidderPreference;
    deployment: string;
};
/**
 * Updates user's reseller type via Post request to User API
 * @function postBidderPreferences
 * @category User API
 * @param {PostBidderPreferencesParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/${bidderId}/preferences
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/cfmxni/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnUpsertBidderPreference
 */

export const postBidderPreferences = ({
    authToken,
    bidderId,
    bidderPreference,
    deployment,
}: PostBidderPreferencesParams) =>
    new Promise<BidderPreferencesResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `${bidderId}/preferences`,
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send(bidderPreference);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
