import { BidIncrementCatalogData } from '@/types/BidIncrement';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getDeployment } from '@/redux/modules/config';
import { postLoadBidIncrements } from '@/redux/modules/bidIncrements/bidIncrements.api';
import { shouldFetchBidIncrements } from '@/redux/modules/bidIncrements/bidIncrements.selectors';

export const fetchBidIncrements = createAsyncThunk<BidIncrementCatalogData[], number>(
    'la/ui/bidIncrements/fetchBidIncrements',
    async (catalogId, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const response = await postLoadBidIncrements({ catalogId, deployment });
        return response.data;
    }
);

export const fetchBidIncrementsIfNeeded = createAsyncThunk<void, number>(
    'la/ui/bidIncrements/fetchBidIncrementsIfNeeded',
    async (catalogId, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetchBidIncrements(state, catalogId)) {
            dispatch(fetchBidIncrements(catalogId));
        }
    }
);
