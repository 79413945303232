import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { BidHistoryItem } from '@/types/BidHistoryItem';
import { createSelector } from '@reduxjs/toolkit';
import { fetchBidHistory } from '@/redux/api/bidHistory';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_BID_HISTORY_FAIL = 'la/domain/bidHistory/LOAD_FAIL';
export const LOAD_BID_HISTORY_REQUEST = 'la/domain/bidHistory/LOAD_REQUEST';
export const LOAD_BID_HISTORY_SUCCESS = 'la/domain/bidHistory/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

export type BidHistorySlice = {
    byId: { [itemId: number]: BidHistoryItem[] };
    loaded: { [itemId: number]: number };
    loading: number[];
};

export const defaultBidHistorySlice: BidHistorySlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state: BidHistorySlice = defaultBidHistorySlice, action: any = {}): BidHistorySlice {
    let existing: BidHistorySlice['byId'];
    let loaded: BidHistorySlice['loaded'];
    let loading: BidHistorySlice['loading'];
    let time: number;

    switch (action.type) {
        case LOAD_BID_HISTORY_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.itemId]),
            };
        case LOAD_BID_HISTORY_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_BID_HISTORY_SUCCESS:
            time = action.meta.actionTime;
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded, [action.meta.itemId]: time };
            loading = cloneDeep(state.loading);
            existing[action.meta.itemId] = action.payload;
            loading = difference(loading, [action.meta.itemId]);
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const bidHistorySlice = createSelector(stateSelector, (state) => state.bidHistory);
const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(bidHistorySlice, (state) => state.byId);

const loadedSelector = createSelector(bidHistorySlice, (state) => state.loaded);

const loadingSelector = createSelector(bidHistorySlice, (state) => state.loading);

export const getBidHistory = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

const getLoadTimeForBidHistory = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isBidHistoryLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchBidHistory = createSelector(
    [idSelector, getBidHistory, getLoadTimeForBidHistory, isBidHistoryLoading],
    (itemId, bidHistory, loaded, loading) => {
        if (!itemId) {
            return false;
        }
        if (bidHistory) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);

/* ACTION CREATORS */
export const fetchBidHistoryIfNeeded = (itemId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    if (shouldFetchBidHistory(state, itemId)) {
        try {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                meta: { actionTime: Date.now() },
                payload: itemId,
                type: LOAD_BID_HISTORY_REQUEST,
            });
            const response = await fetchBidHistory({
                authToken,
                deployment,
                itemId,
            });
            dispatch({
                meta: { actionTime: Date.now(), itemId },
                payload: response.data,
                type: LOAD_BID_HISTORY_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { itemId },
                payload: error,
                type: LOAD_BID_HISTORY_FAIL,
            });
        }
    } else {
        return Promise.resolve();
    }
};
