import { BidderPreference, emptyBidderPreference } from '@/types/BidderPreference';
import ms from 'ms';

export const BIDDER_PREFERENCES_CACHE_TIME = ms('30m');

export type FetchBidderPreferencesParams = {
    bidderId?: number;
    token?: string;
};

export type BidderPreferencesSlice = {
    bidderPreference: BidderPreference;
    bidderPreferenceLoaded: number;
    bidderPreferenceLoading?: boolean;
    error?: string;
};

export const defaultBidderPreferencesSlice: BidderPreferencesSlice = {
    bidderPreference: emptyBidderPreference,
    bidderPreferenceLoaded: 0,
    bidderPreferenceLoading: false,
    error: '',
};
