import { BIDDER_PREFERENCES_CACHE_TIME } from '@/redux/modules/bidder/preferences/bidderPreferences.types';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const bidderPreferencesSlice = createSelector(stateSelector, (state) => state.bidderPreferences);

export const bidderPreferenceSelector = createSelector(bidderPreferencesSlice, (state) => state.bidderPreference);
export const preferredCurrencySelector = createSelector(
    bidderPreferencesSlice,
    (state) => state.bidderPreference?.preferredCurrency
);
export const bidderPreferenceLoadedSelector = createSelector(
    bidderPreferencesSlice,
    (state) => state.bidderPreferenceLoaded
);
export const bidderPreferenceLoadingSelector = createSelector(
    bidderPreferencesSlice,
    (state) => state.bidderPreferenceLoading
);

export const shouldFetchBidderPreferences = createSelector(
    bidderPreferenceLoadingSelector,
    bidderPreferenceLoadedSelector,
    (loading, loaded) => {
        if (loading) {
            return false;
        }
        const now = Date.now();
        return !loaded || now - loaded > BIDDER_PREFERENCES_CACHE_TIME;
    }
);
