import { BidderBiddingInfoAllSlice, defaultBidderBiddingInfoAllSlice } from './bidderBiddingInfoAll.types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchAllBidderBiddingInfo } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.actions';
import { fetchBidderData } from '@/redux/modules/account/user/user.actions';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';

const loadBidderDataReducer = (
    slice: BidderBiddingInfoAllSlice,
    {
        payload,
    }:
        | FulfilledActionFromAsyncThunk<typeof fetchBidderData>
        | FulfilledActionFromAsyncThunk<typeof submitLogin>
        | FulfilledActionFromAsyncThunk<typeof submitLoginWithToken>
) => {
    slice.upcomingBidItemIds = payload.biddingInfos.map(({ itemId }) => itemId);
    return slice;
};

const bidderBiddingInfoAllSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchAllBidderBiddingInfo.pending, (slice) => {
            slice.loading = true;
        });
        builder.addCase(fetchAllBidderBiddingInfo.fulfilled, (slice, { payload }) => {
            const { allBidItemIds, leadingBidItemIds, pastBidItemIds, upcomingBidItemIds, wonBidItemIds } = payload;
            return {
                ...slice,
                allBidItemIds,
                leadingBidItemIds,
                loaded: Date.now(),
                loading: false,
                pastBidItemIds,
                upcomingBidItemIds,
                wonBidItemIds,
            };
        });
        builder.addCase(fetchAllBidderBiddingInfo.rejected, (slice) => {
            slice.loading = false;
        });

        builder.addCase(fetchBidderData.fulfilled, loadBidderDataReducer);
        builder.addCase(submitLogin.fulfilled, loadBidderDataReducer);
        builder.addCase(submitLoginWithToken.fulfilled, loadBidderDataReducer);

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(
            LOG_OUT_BIDDER,
            () => defaultBidderBiddingInfoAllSlice
        );
    },
    initialState: defaultBidderBiddingInfoAllSlice,
    name: 'bidderBiddingInfoAll',
    reducers: {},
});

export const { reducer: bidderBiddingInfoAllReducer } = bidderBiddingInfoAllSlice;
