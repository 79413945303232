import { BidderExistsSlice, defaultBidderExistsSlice } from '@/redux/modules/bidder/exists/bidderExists.types';
import { createSlice } from '@reduxjs/toolkit';
import { DISMISS_MODAL, DISMISS_MODAL_ACTION } from '../../actions';
import { saveExistingBidderEmail } from '@/redux/modules/bidder/exists/bidderExists.actions';
import { submitCreateAccount } from '@/redux/modules/account/createAccount/createAccount.actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';

const clearBidderExistsInfoReducer = (slice: BidderExistsSlice) => {
    slice.email = '';
    return slice;
};

const bidderExistsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(saveExistingBidderEmail.fulfilled, (slice, { payload }) => {
            slice.email = payload;
        });

        builder.addCase(submitLogin.fulfilled, clearBidderExistsInfoReducer);
        builder.addCase(submitLoginWithToken.fulfilled, clearBidderExistsInfoReducer);

        builder.addCase(submitCreateAccount.pending, clearBidderExistsInfoReducer);
        builder.addCase(submitCreateAccount.fulfilled, clearBidderExistsInfoReducer);

        builder.addCase<typeof DISMISS_MODAL, DISMISS_MODAL_ACTION>(DISMISS_MODAL, clearBidderExistsInfoReducer);
    },
    initialState: defaultBidderExistsSlice,
    name: 'bidderExists',
    reducers: {},
});

export const { reducer: bidderExistsReducer } = bidderExistsSlice;
