import { BidIncrement } from '@/types/BidIncrement';
import ms from 'ms';

export const BID_INCREMENTS_CACHE_TIME = ms('30m');

export type BidIncrementsSlice = {
    byId: { [catalogId: number]: BidIncrement[] };
    loaded: { [catalogId: number]: number };
    loading: number[];
};

export const defaultBidIncrementsSlice: BidIncrementsSlice = {
    byId: {},
    loaded: {},
    loading: [],
};
