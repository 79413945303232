import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { UAParser } from 'ua-parser-js';
import browserslist from 'browserslist';

const BROWSERS_LIST = '> 0.5%, last 4 versions and since 2016, Firefox ESR, not dead, not baidu > 0, not kaios > 0';

enum BrowserName {
    chrome = 'chrome',
    edge = 'edge',
    firefox = 'firefox',
    'mobile chrome' = 'and_chr',
    'mobile firefox' = 'and_ff',
    'mobile safari' = 'ios_saf',
    safari = 'safari',
}

export type BrowserSlice = {
    deviceType: string;
    ipAddress?: string;
    isOutdated: boolean;
    majorVersion: number;
    microsoftBrowser: boolean;
    mobile: string;
    name: string;
    os: string;
    osVersion: string;
    userAgentString: string;
};

export const defaultBrowserSlice: BrowserSlice = {
    deviceType: 'computer',
    ipAddress: '',
    isOutdated: false,
    majorVersion: 0,
    microsoftBrowser: false,
    mobile: '',
    name: '',
    os: '',
    osVersion: '',
    userAgentString: '',
};

export const ClientDeviceTypes = {
    COMPUTER: 'computer',
    CONSOLE: 'console',
    EMBEDDED: 'embedded',
    MOBILE: 'mobile',
    PHONE: 'phone',
    SMARTTV: 'smarttv',
    TABLET: 'tablet',
    WEARABLE: 'wearable',
};

export default function reducer(state: BrowserSlice = defaultBrowserSlice, action: any = {}): BrowserSlice {
    switch (action.type) {
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

export const getUiBrowser = createSelector(stateSelector, (state) => state.browser);

export const isUiMobile = createSelector(getUiBrowser, ({ mobile }) => mobile);

export const getClientIpAddress = createSelector(getUiBrowser, ({ ipAddress }) => ipAddress);

export const getMobileBrowserOS = createSelector(getUiBrowser, (uiBrowser) => {
    return uiBrowser.mobile ? uiBrowser.os : '';
});

export const isUiMicrosoftBrowser = createSelector(getUiBrowser, ({ microsoftBrowser }) => microsoftBrowser);

export const isUiTablet = createSelector(isUiMobile, (mobile) => {
    return mobile === ClientDeviceTypes.TABLET;
});

export const getUserAgent = createSelector(getUiBrowser, ({ userAgentString }) => userAgentString);

export const isE2ETest = createSelector(getUserAgent, (userAgent: string) => {
    return userAgent.includes('LA E2E');
});

export const getIsBrowserOutdated = createSelector(
    getUserAgent,
    (userAgent: string) => getBrowserDataFromUA(userAgent).isOutdated
);

export const getBrowserName = createSelector(getUserAgent, (userAgent: string) => getBrowserDataFromUA(userAgent).name);

export const getBrowserMajorVersion = createSelector(
    getUserAgent,
    (userAgent: string) => getBrowserDataFromUA(userAgent).majorVersion
);

export const getBrowserDataFromUA = (userAgentString: string) => {
    const parser = new UAParser(userAgentString);

    const result = parser.getResult();

    let name = '';
    let os = '';
    let osVersion = '';
    let majorVersion = 0;
    let version = 0;
    let mobile = '';
    let deviceType = '';

    if (typeof result?.browser?.name === 'string') {
        // Googlebot don't have a name
        name = result.browser.name.toLowerCase();
    }
    if (result?.browser?.major) {
        version = Number(result.browser.version.split('.').slice(0, 2).join('.'));
        majorVersion = parseInt(result.browser.major, 10);
    }
    if (result?.os) {
        if (typeof result.os.name === 'string') {
            os = result.os.name.toLowerCase();
        }
        if (typeof result.os.version === 'string') {
            osVersion = result.os.version;
        }
    }
    if (result?.device) {
        deviceType = result.device.type || ClientDeviceTypes.COMPUTER; // [console, mobile, tablet, smarttv, wearable, embedded]

        if (result.device.type === ClientDeviceTypes.MOBILE) {
            mobile = ClientDeviceTypes.PHONE;
        } else if (result.device.type === ClientDeviceTypes.TABLET) {
            mobile = result.device.type;
        }
    }

    // get the minimum supported browser versions from the browserslist config
    const minimumBrowserVersions = getMinimumBrowserVersions();

    let isOutdated = false;
    if (Object.prototype.hasOwnProperty.call(minimumBrowserVersions, name)) {
        isOutdated = minimumBrowserVersions[name] > version;
    }
    // IE is always outdated
    if (name === 'ie') {
        isOutdated = true;
    }

    const isGoogle = userAgentString.toLowerCase().includes('google');
    if (isGoogle) {
        isOutdated = false;
    }

    return {
        deviceType,
        isOutdated,
        majorVersion,
        microsoftBrowser: name === 'ie' || name === 'edge',
        mobile,
        name,
        os,
        osVersion,
    };
};

export const isClientDeviceMobile = (userAgentString: string) => {
    const browserData = getBrowserDataFromUA(userAgentString);
    const { deviceType } = browserData;
    const isMobileOs = deviceType !== ClientDeviceTypes.COMPUTER;
    return isMobileOs;
};

type MinimumBrowserVersions = {
    chrome: number;
    edge: number;
    firefox: number;
    'mobile chrome': number;
    'mobile firefox': number;
    'mobile safari': number;
    safari: number;
};
/**
 * Gets the minimum supported browser versions from the browserslist config, for the major browsers.
 * Chrome for Android, Firefox for Android, Chrome, Edge, Firefox, mobile Safari, Opera, Safari
 * @returns MinimumBrowserVersions
 */
export const getMinimumBrowserVersions = () => {
    const minimumSupportedBrowserVersions = {
        [BrowserName.chrome]: 0,
        [BrowserName.edge]: 0,
        [BrowserName.firefox]: 0,
        [BrowserName['mobile chrome']]: 0,
        [BrowserName['mobile firefox']]: 0,
        [BrowserName['mobile safari']]: 0,
        [BrowserName.safari]: 0,
    };
    const allSupportedBrowsers = browserslist(BROWSERS_LIST);

    for (const browser of Object.keys(minimumSupportedBrowserVersions)) {
        const versions = allSupportedBrowsers
            .filter((browserVersion) => browserVersion.includes(browser))
            .map((browserVersion) =>
                // splits version string on spaces to get name and versions
                // then splits on dashes to get all the versions
                // then returns the lowest version
                Math.min(
                    ...browserVersion
                        .split(' ')[1]
                        .split('-')
                        .map((version) => Number(version))
                )
            );
        const minimumVersion = Math.min(...versions);
        minimumSupportedBrowserVersions[browser] = minimumVersion;
    }

    // map browser user agent browser names to browserlist browser names
    const minimumBrowserAccumulator: MinimumBrowserVersions = {
        chrome: 0,
        edge: 0,
        firefox: 0,
        'mobile chrome': 0,
        'mobile firefox': 0,
        'mobile safari': 0,
        safari: 0,
    };
    return Object.keys(BrowserName).reduce((minimumBrowserVersions, browserName) => {
        minimumBrowserVersions[browserName] = minimumSupportedBrowserVersions[BrowserName[browserName]];
        return minimumBrowserVersions;
    }, minimumBrowserAccumulator);
};
