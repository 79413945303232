import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';

/**
 * @category User API
 * @see getBidderRecentBidCount
 */
type GetBidderRecentBidCountParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};
/**
 * Updates user's reseller type via Post request to User API
 * @function getBidderRecentBidCount
 * @category User API
 * @param {GetBidderRecentBidCountParams}
 * @see https://flynn-DEPLOYMENT.liveauctioneers.com/user/${bidderId}/bidcount
 */

export const getBidderRecentBidCount = ({ authToken, bidderId, deployment }: GetBidderRecentBidCountParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `${bidderId}/bidcount`,
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
