import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const bidderCardStatusSlice = createSelector(stateSelector, (state) => state.bidderCardStatus);

export const getBidderCardStatusIsLoading = createSelector(bidderCardStatusSlice, (state) => state.isLoading);

export const getBidderCardStatus = createSelector(bidderCardStatusSlice, (state) => state.status);

export const getIsBidderCardValid = createSelector(bidderCardStatusSlice, (state) => state.status.isValid);
