import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { OPEN_BID_LIMIT_EXCEEDED_MODAL } from './actions';

export type BidLimitExceededSlice = {
    catalogId: number;
};

export const defaultBidLimitExceededSlice: BidLimitExceededSlice = {
    catalogId: 0,
};

export default function reducer(
    state: BidLimitExceededSlice = defaultBidLimitExceededSlice,
    action: any = {}
): BidLimitExceededSlice {
    switch (action.type) {
        case OPEN_BID_LIMIT_EXCEEDED_MODAL:
            return {
                ...state,
                catalogId: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const bidLimitExceededSelector = createSelector(stateSelector, (state) => state.bidLimitExceeded);

export const getBidLimitExceededCatalogId = createSelector(bidLimitExceededSelector, (state) => state.catalogId);
