import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getBidderRecentBidCount } from './bidderRecentBidCount.api';
import { getDeployment } from '@/redux/modules/config';
import { LoadBidderRecentBidCount } from './bidderRecentBidCount.types';

export const loadBidderRecentBidCount = createAsyncThunk<LoadBidderRecentBidCount>(
    'la/domain/bidder/recentBidCount',
    async (_, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);
        const response = await getBidderRecentBidCount({
            authToken,
            bidderId,
            deployment,
        });
        const now = Date.now();
        return {
            actionTime: now,
            data: response.payload,
        };
    }
);
