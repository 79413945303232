import { ApiHosts, handleResponseOld, makePost } from '../../api/helpers';
import { BidIncrementCatalogData } from '@/types/BidIncrement';

/**
 * @category Item API
 * @see postLoadBidIncrements
 */
type FetchBidIncrementsParams = {
    catalogId: number;
    deployment: string;
};

/**
 * POST request to item-api service that sends a set of catalog IDs and returns bid increment data for all catalogs
 * @function fetchBidIncrements
 * @category Item API
 * @param {FetchBidIncrementsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/bid-increments
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/bidding/routes/post-bid-increments-old.ts
 */

/**
 * @category Item API
 * @see postLoadBidIncrements
 */
type PostLoadBidIncrementsResponse = {
    data: BidIncrementCatalogData[];
    message: string;
    success: boolean;
};

export const postLoadBidIncrements = ({ catalogId, deployment }: FetchBidIncrementsParams) =>
    new Promise<PostLoadBidIncrementsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'spa/small/bid-increments',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send({ catalogIds: [catalogId] });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
