import { createSlice } from '@reduxjs/toolkit';
import { defaultRecentBidCountState } from './bidderRecentBidCount.types';
import { loadBidderRecentBidCount } from './bidderRecentBidCount.actions';

const bidderRecentBidCountSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadBidderRecentBidCount.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadBidderRecentBidCount.fulfilled, (state, { payload }) => {
            state.loaded = payload.actionTime;
            state.loading = false;
            state.recentBidCount = payload.data.bidCountPast180Days;
        });

        builder.addCase(loadBidderRecentBidCount.rejected, (state) => {
            state.loaded = 0;
            state.loading = false;
            state.recentBidCount = 0;
        });
    },
    initialState: defaultRecentBidCountState,
    name: 'bidderRecentBidCount',
    reducers: {},
});

export const { reducer } = bidderRecentBidCountSlice;
