export type RecentBidCountState = {
    loaded: number;
    loading: boolean;
    recentBidCount: number;
};

export const defaultRecentBidCountState: RecentBidCountState = {
    loaded: 0,
    loading: false,
    recentBidCount: 0,
};

export type GetBidderRecentBidCountPayload = {
    bidCountPast180Days: number;
};

export type LoadBidderRecentBidCount = {
    actionTime: number;
    data: GetBidderRecentBidCountPayload;
};
