import { createSlice } from '@reduxjs/toolkit';
import { defaultBidderCardStatusSlice } from '@/redux/modules/bidder/cardStatus/bidderCardStatus.types';
import { loadBidderCardStatus } from '@/redux/modules/bidder/cardStatus/bidderCardStatus.actions';

const bidderCardStatusSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadBidderCardStatus.pending, (slice) => {
            slice.isLoading = true;
        });
        builder.addCase(loadBidderCardStatus.fulfilled, (slice, { payload }) => {
            if (!Boolean(payload)) {
                return slice;
            }
            slice.isLoading = false;
            slice.status = payload;
        });
        builder.addCase(loadBidderCardStatus.rejected, (slice) => {
            slice.isLoading = false;
            slice.status = defaultBidderCardStatusSlice.status;
        });
    },
    initialState: defaultBidderCardStatusSlice,
    name: 'bidderCardStatusSlice',
    reducers: {},
});

export const { reducer: bidderCardStatusReducer } = bidderCardStatusSlice;
