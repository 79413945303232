import { BidderPreference } from '@/types/BidderPreference';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { FetchBidderPreferencesParams } from '@/redux/modules/bidder/preferences/bidderPreferences.types';
import { getAuthToken, getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getBidderPreferences, postBidderPreferences } from './bidderPreferences.api';
import { getDeployment } from '@/redux/modules/config';
import { shouldFetchBidderPreferences } from '@/redux/modules/bidder/preferences/bidderPreferences.selectors';

export const fetchBidderPreferences = createAsyncThunk<BidderPreference, FetchBidderPreferencesParams>(
    'la/ui/bidderPreferences/fetchBidderPreferences',
    async ({ bidderId, token }, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        const storedBidderId = getBidderId(state);
        const response = await getBidderPreferences({
            authToken: token || authToken,
            bidderId: bidderId || storedBidderId,
            deployment,
        });
        return response.payload;
    }
);

export const fetchBidderPreferencesIfNeeded = createAsyncThunk<void, FetchBidderPreferencesParams>(
    'la/ui/bidderPreferences/fetchBidderPreferencesIfNeeded',
    async (params, { dispatch, getState }) => {
        const state = getState();
        const loggedIn = isUserLoggedIn(state);

        // After login, we need to use params since the selector is slower.
        const hasLoggedInParams = Boolean(params.bidderId) && Boolean(params.token);

        if (shouldFetchBidderPreferences(state) && (loggedIn || hasLoggedInParams)) {
            await dispatch(fetchBidderPreferences(params));
        }
    }
);

export const updateBidderPreferences = createAsyncThunk<BidderPreference, BidderPreference>(
    'la/ui/bidderPreferences/updateBidderPreferences',
    async (bidderPreference, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const response = await postBidderPreferences({
            authToken,
            bidderId,
            bidderPreference,
            deployment,
        });
        return response.payload;
    }
);
