import { BID_INCREMENTS_CACHE_TIME } from '@/redux/modules/bidIncrements/bidIncrements.types';
import { BidIncrement } from '@/types/BidIncrement';
import { createSelector } from '@reduxjs/toolkit';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { GlobalState } from '@/redux/store';
import isEmpty from 'lodash/isEmpty';

const stateSelector = (state: GlobalState) => state;
const bidIncrementsSlice = createSelector(stateSelector, (state) => state.bidIncrements);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(bidIncrementsSlice, (state) => state.byId);

const loadedSelector = createSelector(bidIncrementsSlice, (state) => state.loaded);

const loadingSelector = createSelector(bidIncrementsSlice, (state) => state.loading);

export const getBidIncrementsByCatalogId = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

export const getBidIncrementsByItemId = createSelector(
    [byIdSelector, getItemSummary],
    (byId, item): BidIncrement[] => byId[item.catalogId] || []
);

export const getLoadTimeForBidIncrements = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

const isBidIncrementsLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const shouldFetchBidIncrements = createSelector(
    [idSelector, getBidIncrementsByCatalogId, getLoadTimeForBidIncrements, isBidIncrementsLoading],
    (catalogId, item, loaded, loading) => {
        if (!catalogId) {
            return false;
        }
        if (!isEmpty(item)) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < BID_INCREMENTS_CACHE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);
