import { BIDDING_INFO_ALL_SLICE_CACHE_TIME } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.types';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const bidderBiddingInfoAllSlice = createSelector(stateSelector, (state) => state.bidderBiddingInfoAll);

const idSelector = (_: GlobalState, id: number) => id;
const loadedSelector = createSelector(bidderBiddingInfoAllSlice, (state) => state.loaded);
const loadingSelector = createSelector(bidderBiddingInfoAllSlice, (state) => state.loading);

export const shouldFetchBidderBiddingInfo = createSelector([loadedSelector, loadingSelector], (loaded, loading) => {
    const time = Date.now();
    const diff = time - loaded;
    if (diff < BIDDING_INFO_ALL_SLICE_CACHE_TIME) {
        return false;
    }
    return !loading;
});

export const getMyBidItemIds = createSelector(bidderBiddingInfoAllSlice, (state) => ({
    allBidItemIds: state.allBidItemIds,
    leadingBidItemIds: state.leadingBidItemIds,
    pastBidItemIds: state.pastBidItemIds,
    upcomingBidItemIds: state.upcomingBidItemIds,
    wonBidItemIds: state.wonBidItemIds,
}));

export const getWonItem = createSelector([bidderBiddingInfoAllSlice, idSelector], (state, id) =>
    state.wonBidItemIds.includes(id)
);

export const getBidderBidOnItemIds = createSelector(getMyBidItemIds, ({ allBidItemIds }) => allBidItemIds);
