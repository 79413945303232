import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import {
    LIVE_BID_ACCEPTED,
    LIVE_BID_RETRACTED,
    LIVE_LOT_PASSED,
    LIVE_LOT_REOPENED,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SOLD,
    LIVE_MISSIVE,
    LIVE_NEXT_LOT_LOADED,
} from './actions';
import cloneDeep from 'lodash/cloneDeep';

type ByIdState = { [itemId: number]: number };

export type BidCountDownSlice = {
    byId: ByIdState;
};

export const defaultBidCountDownSlice: BidCountDownSlice = {
    byId: {},
};

export default function reducer(
    state: BidCountDownSlice = defaultBidCountDownSlice,
    action: any = {}
): BidCountDownSlice {
    let existing: ByIdState;

    switch (action.type) {
        case LIVE_BID_ACCEPTED:
        case LIVE_BID_RETRACTED:
        case LIVE_LOT_REOPENED:
        case LIVE_NEXT_LOT_LOADED:
            existing = cloneDeep<ByIdState>(state.byId);
            existing[action.payload.itemId] = Date.now();
            return {
                ...state,
                byId: existing,
            };
        case LIVE_MISSIVE:
            if (action.payload.isTimed && action.payload.missiveText === 'Fair Warning... This lot is about to close') {
                existing = cloneDeep<ByIdState>(state.byId);
                existing[action.payload.itemId] = -Date.now();
                return {
                    ...state,
                    byId: existing,
                };
            }

            return state;
        case LIVE_LOT_SOLD:
        case LIVE_LOT_PASSED:
        case LIVE_LOT_SKIPPED:
            // We need to reset the state to 0 when one of these events happen
            existing = cloneDeep<ByIdState>(state.byId);
            existing[action.payload.itemId] = 0;
            return {
                ...state,
                byId: existing,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const bidCountDownSlice = createSelector(stateSelector, (state) => state.bidCountDown);
const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(bidCountDownSlice, (state) => state.byId);

export const getCountDown = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || 0);
