import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchBidderBiddingAll, FetchBidderBiddingAllTransformedResponse } from './bidderBiddingInfoAll.api';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { shouldFetchBidderBiddingInfo } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.selectors';

export const fetchAllBidderBiddingInfo = createAsyncThunk<FetchBidderBiddingAllTransformedResponse['payload']>(
    'la/ui/bidderBiddingInfoAll/fetchAllBidderBiddingInfo',
    async (_, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { payload } = await fetchBidderBiddingAll({
            authToken,
            deployment,
        });
        return payload;
    }
);

export const fetchAllBidderBiddingInfoIfNeeded = createAsyncThunk<void, boolean>(
    'la/ui/bidderingBiddingInfoAll/fetchAllBidderingBiddingInfoIfNeeded',
    async (force, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        if (authToken && (force || shouldFetchBidderBiddingInfo(state))) {
            dispatch(fetchAllBidderBiddingInfo());
        }
    }
);
