import {
    BidderCardStatusType,
    LoadBidderCardStatusIfNeededParams,
    LoadBidderCardStatusParams,
} from '@/redux/modules/bidder/cardStatus/bidderCardStatus.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken, getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getBidderCardStatus } from './bidderCardStatus.api';
import { getBidderCardStatusIsLoading } from '@/redux/modules/bidder/cardStatus/bidderCardStatus.selectors';
import { getDeployment } from '@/redux/modules/config';

export const loadBidderCardStatus = createAsyncThunk<BidderCardStatusType, LoadBidderCardStatusParams>(
    'la/ui/bidderCardStatus/loadBidderCardStatus',
    async ({ authToken, bidderId, deployment }) => {
        if (!Boolean(bidderId) || bidderId < 0) {
            return;
        }
        const response = await getBidderCardStatus({
            authToken,
            bidderId,
            deployment,
        });
        return response.payload;
    }
);

export const fetchBidderCardStatusIfNeeded = createAsyncThunk<void, LoadBidderCardStatusIfNeededParams>(
    'la/ui/bidderCardStatus/fetchBidderCardStatusIfNeeded',
    async ({ bidderId, token }, { dispatch, getState }) => {
        const state = getState();
        const loggedIn = isUserLoggedIn(state);
        if (!getBidderCardStatusIsLoading(state) && loggedIn) {
            await dispatch(
                loadBidderCardStatus({
                    authToken: token || getAuthToken(state),
                    bidderId: bidderId || getBidderId(state),
                    deployment: getDeployment(state),
                })
            );
        }
    }
);
