import {
    BidderPreferencesSlice,
    defaultBidderPreferencesSlice,
} from '@/redux/modules/bidder/preferences/bidderPreferences.types';
import { createSlice } from '@reduxjs/toolkit';
import {
    fetchBidderPreferences,
    updateBidderPreferences,
} from '@/redux/modules/bidder/preferences/bidderPreferences.actions';
import { FulfilledActionFromAsyncThunk, RejectedActionFromAsyncThunk } from '@/redux/createAsyncThunk';

const pendingPreferencesReducer = (slice: BidderPreferencesSlice) => {
    slice.bidderPreferenceLoading = true;
    slice.bidderPreferenceLoaded = 0;
    return slice;
};

const fulfilledPreferencesReducer = (
    slice: BidderPreferencesSlice,
    { payload }: FulfilledActionFromAsyncThunk<typeof fetchBidderPreferences | typeof updateBidderPreferences>
) => {
    slice.bidderPreference = payload;
    slice.bidderPreferenceLoaded = Date.now();
    slice.bidderPreferenceLoading = false;
    return slice;
};

const rejectedPreferencesReducer = (
    slice: BidderPreferencesSlice,
    { error }: RejectedActionFromAsyncThunk<typeof fetchBidderPreferences | typeof updateBidderPreferences>
) => {
    slice.bidderPreferenceLoaded = 0;
    slice.bidderPreferenceLoading = false;
    slice.error = error.message;
    return slice;
};

const bidderPreferencesSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchBidderPreferences.pending, pendingPreferencesReducer);
        builder.addCase(fetchBidderPreferences.fulfilled, fulfilledPreferencesReducer);
        builder.addCase(fetchBidderPreferences.rejected, rejectedPreferencesReducer);

        builder.addCase(updateBidderPreferences.pending, pendingPreferencesReducer);
        builder.addCase(updateBidderPreferences.fulfilled, fulfilledPreferencesReducer);
        builder.addCase(updateBidderPreferences.rejected, rejectedPreferencesReducer);
    },
    initialState: defaultBidderPreferencesSlice,
    name: 'bidderPreferences',
    reducers: {},
});

export const { reducer: bidderPreferencesReducer } = bidderPreferencesSlice;
