import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchBidderCardStatusIfNeeded } from '../cardStatus/bidderCardStatus.actions';
import { fetchBidderPreferencesIfNeeded } from '../preferences/bidderPreferences.actions';
import { fetchCertificatesIfNeeded } from '../../certCapture';
import { fetchConversationFoldersIfNeeded } from '../../conversation/folders/conversationFolders.actions';
import { fetchLAPaymentCardIfNeeded } from '../../creditCard';
import { fetchNotificationsIfNeeded } from '../../notification';
import { fetchSavedSearchesIfNeeded } from '../../savedSearch';
import { fetchSmsAlertsIfNeeded } from '../../smsAlerts';
import { fetchWonItemsCountIfNeeded } from '../../wonItemsCount/wonItemsCount.actions';
import { LoadBidderDataParams } from '@/redux/modules/bidder/data/bidderData.types';
import { ManageSavedSearchSortOrders } from '@/enums';
import ResellerTypes from '@/enums/resellerTypes';

export const loadBidderData = createAsyncThunk<void, LoadBidderDataParams>(
    'la/ui/bidderData/loadBidderData',
    async ({ bidderId, resellerType, token }, { dispatch }) => {
        /**
         * If you add any actions that call the API, please make sure to update the spy-creating helper function
         * @see {setupBidderDataSpies}
         */

        const bidderDataCalls = [
            dispatch(fetchConversationFoldersIfNeeded({ bidderId, token })),
            dispatch(fetchSmsAlertsIfNeeded(token)),
            dispatch(fetchLAPaymentCardIfNeeded(bidderId, token)),
            dispatch(fetchBidderCardStatusIfNeeded({ bidderId, token })),
            dispatch(fetchBidderPreferencesIfNeeded({ bidderId, token })),
            dispatch(fetchNotificationsIfNeeded(true, token)),
            dispatch(fetchWonItemsCountIfNeeded({ authToken: token, bidderId })),
            dispatch(
                fetchSavedSearchesIfNeeded(false, 1, 24, ManageSavedSearchSortOrders.DATE_ADDED_NEWEST, '', token)
            ),
        ];

        if (resellerType === ResellerTypes.RESELLER) {
            bidderDataCalls.push(dispatch(fetchCertificatesIfNeeded()));
        }
        await Promise.all(bidderDataCalls);
    }
);
