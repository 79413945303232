import ms from 'ms';

export const BIDDING_INFO_ALL_SLICE_CACHE_TIME = ms('15m');

export type BidderBiddingInfoAllSlice = {
    allBidItemIds: number[];
    leadingBidItemIds: number[];
    loaded: number;
    loading: boolean;
    pastBidItemIds: number[];
    upcomingBidItemIds: number[];
    wonBidItemIds: number[];
};

export const defaultBidderBiddingInfoAllSlice: BidderBiddingInfoAllSlice = {
    allBidItemIds: [],
    leadingBidItemIds: [],
    loaded: 0,
    loading: false,
    pastBidItemIds: [],
    upcomingBidItemIds: [],
    wonBidItemIds: [],
};
