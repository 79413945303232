import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { BidLimitBalance, fetchBidLimitBalance } from '@/redux/api/bidLimit';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken, getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { getDeployment } from './config';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_BID_LIMIT_FAIL = 'la/domain/bidLimit/LOAD_FAIL';
export const LOAD_BID_LIMIT_REQUEST = 'la/domain/bidLimit/LOAD_REQUEST';
export const LOAD_BID_LIMIT_SUCCESS = 'la/domain/bidLimit/LOAD_SUCCESS';

export const BID_LIMIT_SLICE_CACHE_TIME = ms('1s');

export type BidLimitSlice = {
    byId: { [catalogId: number]: { balance: number } };
    loaded: { [catalogId: number]: number };
    loading: number[];
};

export const defaultBidLimitSlice: BidLimitSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state = defaultBidLimitSlice, action: any = {}): BidLimitSlice {
    let existing: BidLimitSlice['byId'];
    let loaded: BidLimitSlice['loaded'];
    let loading: BidLimitSlice['loading'];
    let time: number;

    switch (action.type) {
        case LOAD_BID_LIMIT_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.catalogId]),
            };
        case LOAD_BID_LIMIT_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_BID_LIMIT_SUCCESS:
            const payload: BidLimitBalance = action.payload;
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;
            existing[action.payload.catalogId] = {
                balance: payload.bidLimitRemaining,
            };
            loaded[action.payload.catalogId] = time;
            loading = difference(loading, [action.payload.catalogId]);
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const idSelector = (_: GlobalState, id: number) => id;

export const bidLimitSelector = createSelector(stateSelector, (state) => state.bidLimit);

const byIdSelector = createSelector(bidLimitSelector, (state) => state.byId);

const loadedSelector = createSelector(bidLimitSelector, (state) => state.loaded);

const loadingSelector = createSelector(bidLimitSelector, (state) => state.loading);

export const getIsBidLimitLoaded = createSelector([loadedSelector, idSelector], (loaded, id) =>
    loaded.hasOwnProperty(id)
);

export const getTotalBidLimitBalance = (state: GlobalState, catalogId: number) => {
    const byId = byIdSelector(state);
    const catalogRegistration = getCatalogRegistration(state, catalogId);
    if (!catalogRegistration.limited) {
        return Infinity;
    }

    const data = byId[catalogId] || { balance: 0 };
    return data.balance || 0;
};

export const getBidLimitBalance = (state: GlobalState, catalogId: number) => {
    const byId = byIdSelector(state);
    const catalogRegistration = getCatalogRegistration(state, catalogId);
    if (!catalogRegistration.limited) {
        return Infinity;
    }

    const data = byId[catalogId] || { balance: 0 };
    const serverBalance = data.balance || 0;

    return Math.max(serverBalance, 0);
};

const getLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetch = (state: GlobalState, catalogId: number) => {
    const item = getBidLimitBalance(state, catalogId);
    const loaded = getLoadTime(state, catalogId);
    const loading = isLoading(state, catalogId);
    const authToken = getAuthToken(state);
    const bidderId = getBidderId(state);

    if (!authToken) {
        return false;
    }
    if (bidderId === -1) {
        return false;
    }
    if (!catalogId) {
        return false;
    }
    if (!isEmpty(item) || item > 0) {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < BID_LIMIT_SLICE_CACHE_TIME) {
            return false;
        }
    }
    return !loading;
};

type FetchBidLimitProps = {
    catalogId: number;
    force?: boolean;
    itemId?: number;
};

/* ACTION CREATORS */
export const fetchBidLimitBalanceIfNeeded =
    ({ catalogId, force = false, itemId }: FetchBidLimitProps) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const loggedIn = isUserLoggedIn(state);
        if ((force || shouldFetch(state, catalogId)) && loggedIn) {
            const bidderId = getBidderId(state);
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            try {
                dispatch({
                    meta: { actionTime: Date.now() },
                    payload: catalogId,
                    type: LOAD_BID_LIMIT_REQUEST,
                });
                const response = await fetchBidLimitBalance({
                    authToken,
                    bidderId,
                    catalogId,
                    deployment,
                    itemId,
                });
                dispatch({
                    meta: { actionTime: Date.now(), catalogId },
                    payload: response.payload,
                    type: LOAD_BID_LIMIT_SUCCESS,
                });
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { catalogId },
                    payload: error,
                    type: LOAD_BID_LIMIT_FAIL,
                });
            }
        } else {
            return Promise.resolve();
        }
    };
